import ReactPlayer from "react-player";
import styles from "./styles.module.css";
import { getQueryParam } from "../../../utils/url";
import { useEffect, useState } from "react";
import MainLayout from "../../MainLayout";
import { useNavigate } from "react-router";
import { getQrCodeLocalDB } from "../../../utils/qrCodeFuncs";
import { tQRCodeItem } from "../../../resources/types/items/tQRCodeItem";
import Text from "../../../components/Text";
import Button from "../../../components/Button";
import { tVideoItem } from "../../../resources/types/items/tVideoItem";

const Review = () => {
  const [params, setParams] = useState<tVideoItem>({
    source: "",
    ext: "",
    recipient: "",
    orientation: "",
  });
  const [code, setCode] = useState<tQRCodeItem>();
  const navigate = useNavigate();

  useEffect(() => {
    const source = getQueryParam("source");
    const ext = getQueryParam("ext");
    const recipient = getQueryParam("recipient");
    const orientation = getQueryParam("orientation");
    if (source) setParams((prev) => ({ ...prev, source }));
    if (ext) setParams((prev) => ({ ...prev, ext }));
    if (recipient) setParams((prev) => ({ ...prev, recipient }));
    if (orientation) setParams((prev) => ({ ...prev, orientation }));
    let tempCode: tQRCodeItem | undefined = getQrCodeLocalDB();
    tempCode && setCode(tempCode);
  }, []);

  const onUpload = () => {
    if (params)
      navigate(
        `/upload?source=${params.source}&ext=${params.ext}&recipient=${params.recipient}&orientation=${params.orientation}`
      );
  };
  const Footer = () => {
    return (
      <Button className={styles.button} title={"UPLOAD"} onPress={onUpload} />
    );
  };
  return (
    <MainLayout backHeader={true} onBackPress={() => navigate(-1)}>
      <div className={styles.middle}>
        <Text className={styles.title}>Review</Text>
        <div className={styles.video_container}>
          {params.source ? (
            <ReactPlayer
              width="100%"
              height="100%"
              controls
              url={params.source}
              style={{}}
            />
          ) : (
            <Text> Your Video is Not Found </Text>
          )}
        </div>
        <div className={styles.text_container}>
          <Text className={styles.recipient}>{params.recipient}</Text>
          {/* {code && <Text className={styles.design}>{code.Design}</Text>} */}
        </div>
      </div>
      <Footer />
    </MainLayout>
  );
};
export default Review;
