import Text from "../Text";
import { useEffect, useState } from "react";
import styles from "./styles.module.css";
import { ReactComponent as LogoSVG } from "../../resources/assets/svg/logo.svg";
import { ReactComponent as LeftArrowSVG } from "../../resources/assets/svg/left-arrow.svg";
import { tStickyHeaderProps } from "../../resources/types/components/tStickyHeaderProps";

const StickyHeader = ({
  showBack,
  onBackPress,
  showLogo,
  title,
}: tStickyHeaderProps) => {
  const [header, setHeader] = useState(false);
  useEffect(() => {
    const scrollYPos: any = window.addEventListener("scroll", () => {
      window.scrollY > 50 ? setHeader(true) : setHeader(false);
    });

    //remove event
    return () => window.removeEventListener("scroll", scrollYPos);
  }, []);
  const headerClass = `${styles.header} ${
    header ? styles.headerScrolled : styles.headerDefault
  }`;
  return (
    <header className={headerClass}>
      <div className={styles.container}>
        <div className={styles.backHeader}>
          <div className={styles.left_section}>
            {showBack && (
              <div onClick={onBackPress}>
                <LeftArrowSVG />
              </div>
            )}
          </div>
          <div className={styles.middle_section}>
            {showLogo && <LogoSVG className={styles.logo_icon} />}
          </div>
          <div className={styles.right_section}></div>
        </div>
        <Text className={styles.headline}>{title}</Text>
      </div>
    </header>
  );
};
export default StickyHeader;
