import { ThemeContext } from "../../App";
import Header from "../../components/Header";
import SmallPadding from "../../components/SmallPadding";
import { tMainLayoutProps } from "../../resources/types/pages/tMainLayoutProps";
import "./styles.css";
import { useContext } from "react";
const MainLayout = ({
  children,
  topPadding = false,
  showGallery = false,
  backHeader,
  tabHeader,
  logoHeader,
  footer,
  props,
  onLogoutPress,
  onPlusPress,
  onBackPress,
  onGalleryPress,
}: tMainLayoutProps) => {
  const theme = useContext(ThemeContext);
  return (
    <div className={"container"}>
      {backHeader && (
        <Header
          showBack={true}
          showLogo={true}
          onBackPress={onBackPress}
          {...props}
        />
      )}
      {tabHeader && (
        <Header
          showLogout={true}
          showLogo={true}
          showPlus={true}
          onLogoutPress={onLogoutPress}
          onPlusPress={onPlusPress}
          {...props}
        />
      )}
      {logoHeader && (
        <Header
          showLogo={true}
          showGallery={showGallery}
          onGalleryPress={onGalleryPress}
        />
      )}
      {topPadding && <SmallPadding />}
      {children}
      {footer && <div className="footer">{footer}</div>}
    </div>
  );
};
export default MainLayout;
