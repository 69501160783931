import { useState } from "react";
import { useNavigate } from "react-router-dom";
import MainLayout from "../../MainLayout";
import { useAuthenticator } from "@aws-amplify/ui-react";
import GalleryCameraAlert from "../../../alerts/GalleryCameraAlert";
import Text from "../../../components/Text";
import Button from "../../../components/Button";
import { ReactComponent as GirlLogoSVG } from "../../../resources/assets/svg/girl_logo.svg";
import styles from "./styles.module.css";
import { colors } from "../../../resources/theme/colors";
import { getMetadata, getThumbnails } from "video-metadata-thumbnails";
import { getVideoOrientation } from "../../../utils/videoFuncs";

const Occasion = () => {
  const navigate = useNavigate();
  const [showAlert, setShowAlert] = useState(false);
  const [recipient, setRecipient] = useState("");
  const { authStatus } = useAuthenticator((context) => [context?.authStatus]);
  const [errorMessage, setErrorMessage] = useState("");

  const handleInputChange = (event) => {
    setErrorMessage("");
    setRecipient(event.target.value);
  };
  // React.ChangeEvent<HTMLInputElement>

  const onGetVideo = async (event) => {
    if (event.target) {
      var url = URL.createObjectURL(event?.target?.files[0]);
      let lastDotIndex = event?.target?.files[0].name.lastIndexOf(".");
      // Extract the extension including the dot
      let extensionWithDot =
        event?.target?.files[0].name.substring(lastDotIndex);
      const metadata = await getMetadata(url);
      let orientation = getVideoOrientation(metadata.width, metadata.height);
      navigate(
        `/review?source=${url}&ext=${extensionWithDot}&recipient=${recipient}&orientation=${orientation}`
      );
    }
  };
  const textStyle = {
    fontWeight: "400",
    textAlign: "left",
    width: "90%",
    color: colors.red,
    margin: "auto",
    fontSize: "0.8rem",
    maxWidth: "400px",
    width: "90%",
  };
  const onNext = () => {
    recipient.trim() === ""
      ? setErrorMessage("This input is Required")
      : setShowAlert(true);
  };
  const Footer = () => {
    return (
      <Button className={styles.button} title={"NEXT"} onPress={onNext}>
        Next
      </Button>
    );
  };
  return (
    <MainLayout
      onBackPress={() => navigate(-1)}
      logoHeader={authStatus === "authenticated"}
      backHeader={authStatus !== "authenticated"}
      showGallery={authStatus === "authenticated"}
      onGalleryPress={()=>navigate("/gallery")}
    >
      <div className={styles.middle}>
        <Text className={styles.title}>Occasion For</Text>
        <GirlLogoSVG className={styles.occasion} />
        <input
          type="text"
          value={recipient}
          onChange={handleInputChange}
          placeholder="Name"
          className={styles.input_name}
          required
        />
        <Text style={textStyle}>{errorMessage}</Text>
        <Footer />
      </div>
      {showAlert && (
        <GalleryCameraAlert
          onGetVideo={onGetVideo}
          onClose={() => setShowAlert(false)}
        />
      )}
    </MainLayout>
  );
};
export default Occasion;
