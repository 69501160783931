import ReactPlayer from "react-player";
import styles from "./styles.module.css";
import { getQueryParam, getQueryUrl } from "../../../utils/url";
import { useEffect, useState } from "react";
import MainLayout from "../../MainLayout";
import { useNavigate } from "react-router";
import Text from "../../../components/Text";
import Button from "../../../components/Button";

const VideoPlayer = () => {
  const [params, setParams] = useState<{
    source: string;
    design: string;
    recipient: string;
    isBack: string;
  }>({
    source: "",
    design: "",
    recipient: "",
    isBack: "false", // I will send this param only when i am in Gallery
  });

  const navigate = useNavigate();

  useEffect(() => {
    const source = getQueryParam("source");
    const design = getQueryParam("Design");
    const isBack = getQueryParam("isBack");
    const recipient = getQueryParam("recipient");
    if (source) setParams((prev) => ({ ...prev, source }));
    if (design) setParams((prev) => ({ ...prev, design }));
    if (recipient) setParams((prev) => ({ ...prev, recipient }));
    if (isBack) setParams((prev) => ({ ...prev, isBack }));
  }, []);

  const createLink = () => {
    let url = "";
    if (params.source !== "") {
      let path = getQueryUrl();
      let sourceVideo = params.source;
      url = path + "?source=" + sourceVideo;
    }
    return url;
  };

  const onShare = async () => {
    let url = createLink();
    if (url === "") return alert("something wrong, URL is blank");
    try {
      if (navigator.share) {
        await navigator.share({
          title: "Share Video",
          text: "Check out this video!",
          url: createLink(),
        });
      } else {
        // Fallback for browsers that do not support Web Share API
        // You can implement your custom share logic here
        alert("Your browser does not support sharing.");
      }
    } catch (error) {
      console.error("Error sharing: ", error);
    }
  };

  const Footer = () => {
    return (
      <Button title={"SHARE"} onPress={onShare} className={styles.button} />
    );
  };
  return (
    <MainLayout
      logoHeader={params.isBack === "false"}
      backHeader={params.isBack === "true"}
      onBackPress={() => navigate(-1)}
    >
      <div className={styles.middle}>
        <div className={styles.video_container}>
          {params.source ? (
            <ReactPlayer
              width="100%"
              height="100%"
              controls
              url={params.source}
              style={{}}
            />
          ) : (
            <Text> Your Video is Not Found </Text>
          )}
        </div>
        <div className={styles.text_container}>
          <Text className={styles.recipient}>{params.recipient}</Text>
          {/* <Text className={styles.design}>{params.design}</Text> */}
        </div>
      </div>
      <Footer />
    </MainLayout>
  );
};
export default VideoPlayer;
