import { ReactComponent as GallerySVG } from "../../resources/assets/svg/gallery.svg";
import { ReactComponent as CameraSVG } from "../../resources/assets/svg/camera.svg";
import styles from "./styles.module.css";
import { tGalleryCameraAlertProps } from "../../resources/types/alerts/tGalleryCameraAlertProps";
import Text from "../../components/Text";
const GalleryCameraAlert = ({
  onGetVideo,
  onClose,
}: tGalleryCameraAlertProps) => {
  const onClickCamera = () => {
    const cameraInput = document.getElementById("camera_input");
    // Check if the element exists
    if (cameraInput) {
      // Call click method if element exists
      cameraInput.click();
    }
  };
  const onClickGallery = () => {
    const galleryInput = document.getElementById("gallery_input");
    // Check if the element exists
    if (galleryInput) {
      // Call click method if element exists
      galleryInput.click();
    }
  };
  return (
    <div id={styles.background}>
      {/*//onClick={onClose}*/}
      <div id={styles.container}>
        <Text className={styles.title}>I want</Text>
        <div id={styles.middle_section}>
          <div
            className={[styles.text, styles.click].join(" ")}
            onClick={onClickCamera}
          >
            <div className={styles.option}>
              <CameraSVG width={"50%"} height={"40px"} />
            </div>
            <Text>TAKE A LIVE SHOT</Text>
            <input
              id="camera_input"
              type="file"
              accept="video/*"
              capture="environment"
              onChange={onGetVideo}
              hidden
            />
          </div>
          <div
            className={[styles.text, styles.click].join(" ")}
            onClick={onClickGallery}
          >
            <div className={styles.option}>
              <GallerySVG width={"50%"} height={"40px"} />
            </div>
            <Text>CHOOSE FROM GALLERY</Text>
            <input
              id="gallery_input"
              type="file"
              accept="video/*"
              onChange={onGetVideo}
              hidden
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default GalleryCameraAlert;
