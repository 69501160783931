import { tCardProps } from "../../resources/types/components/tCardProps";
import { getDateTime } from "../../utils/dateFuncs";
import Text from "../Text";
import styles from "./styles.module.css";

const Card = ({
  onPress,
  design,
  timestamp,
  recipient,
  thumbnail,
}: tCardProps) => {
  let dateTime = { date: "", time: "" };
  if (timestamp) {
    dateTime = getDateTime(timestamp);
  }
  return (
    <div onClick={onPress} className={styles.card_container}>
      <img src={thumbnail} alt="Card Image" className={styles.image} />
      <div className={styles.text_section}>
        <Text className={styles.date}>
          {dateTime.date} at {dateTime.time}
        </Text>
        <Text className={styles.name}>{recipient}</Text>
        {/* <Text className={styles.design}>{design}</Text> */}
      </div>
    </div>
  );
};

export default Card;
