import {
  Authenticator,
  CheckboxField,
  useAuthenticator,
} from "@aws-amplify/ui-react";
import CheckBox from "../../../components/CheckBox";
import { useState } from "react";
import styles from "./styles.module.css";
import Text from "../../../components/Text";
import { useNavigate } from "react-router-dom";
import { colors } from "../../../resources/theme/colors";
import SocialProviders from "../../../components/SocialProviders";

const SignUp = {
  FormFields() {
    const navigate = useNavigate();
    const { validationErrors } = useAuthenticator();
    const [notification, setNotification] = useState(false);
    const [terms, setTerms] = useState(false);
    return (
      <>
        {/* Re-use default `Authenticator.SignUp.FormFields` */}
        <Authenticator.SignUp.FormFields />
        <div className={styles.checkContainer}>
          <CheckBox
            selected={notification}
            label={"Receive Notifications"}
            onPress={() => setNotification((prev) => !prev)}
          />
          <CheckBox
            selected={terms}
            label={"I agree with  "}
            onPress={() => setTerms((prev) => !prev)}
          >
            <div onClick={() => navigate(`/terms?withConfirm=false`)}>
              <Text
                className={styles.textCheckbox}
                style={{ color: colors.primary }}
              >
                Terms & Conditions
              </Text>
            </div>
          </CheckBox>
          {/* <CheckboxField
                  errorMessage={validationErrors.acknowledgement as string}
                  hasError={!!validationErrors.acknowledgement}
                  name="acknowledgement"
                  label="I agree with the Terms and Conditions"
                /> */}
        </div>
      </>
    );
  },
  Footer() {
    return (
      <>
        <Text className={styles.textFooter}>Or sign up with</Text>
        <SocialProviders />
      </>
    );
  },
};
export default SignUp;
