import { UploadDataOutput, uploadData } from "aws-amplify/storage";
import { useEffect, useState } from "react";
import { config } from "../../../config";
import { tQRCodeItem } from "../../../resources/types/items/tQRCodeItem";
import MainLayout from "../../MainLayout";
import { useNavigate } from "react-router-dom";
import { getQueryParam } from "../../../utils/url";
import {
  clearQrCodeLocalDB,
  getQrCodeLocalDB,
} from "../../../utils/qrCodeFuncs";
import {
  activeQRCodeUser,
  activeQRcodeGuest,
} from "../../../api/services/Code";
import { useAuthenticator } from "@aws-amplify/ui-react";
import Text from "../../../components/Text";
import styles from "./styles.module.css";
import { ReactComponent as UploadingSVG } from "../../../resources/assets/svg/upload.svg";
import ProgressBar from "../../../components/ProgressBar";
import { awsExports } from "../../../aws-config";
import { tVideoItem } from "../../../resources/types/items/tVideoItem";
import { refreshToken } from "../../../utils/authFuncs";

const VideoUpload = () => {
  const [progress, setProgress] = useState<number>(0);
  const [codeInfo, setCodeInfo] = useState<tQRCodeItem>({
    QRID: "",
    Design: "",
  });
  const [videoInfo, setVideoInfo] = useState<tVideoItem>({
    source: "",
    ext: "",
    recipient: "",
    orientation: "",
  });
  const { authStatus } = useAuthenticator((context) => [context?.authStatus]);
  const navigate = useNavigate();
  let uploadTask: UploadDataOutput | null = null;
  useEffect(() => {
    let code: tQRCodeItem = getQrCodeLocalDB();
    setCodeInfo(code);
    const source = getQueryParam("source");
    const ext = getQueryParam("ext");
    const recipient = getQueryParam("recipient");
    const orientation = getQueryParam("orientation");
    if (source && ext && code) upload(source, ext, code);
    if (source && ext && recipient && orientation) {
      setVideoInfo({
        source: source,
        ext: ext,
        recipient: recipient,
        orientation: orientation,
      });
    }
  }, []);
  const activateCode = () => {
    config.DEBUG &&
      console.log(
        "********** orientation of video: ",
        videoInfo.orientation,
        "**********"
      );
    authStatus === "authenticated" &&
      refreshToken()
        .then(() =>
          activeQRCodeUser({
            QRID: codeInfo.QRID,
            sourcemap: `s3://${awsExports.BUCKET}/assets02/${codeInfo.QRID}${videoInfo.ext}`,
            // sourcemap: `s3://${awsExports.BUCKET}/${
            //   videoInfo.orientation === "landscape" ? "assets02" : "assets01"
            // }/${codeInfo.QRID}${videoInfo.ext}`,
            recipient: videoInfo.recipient,
          })
        )
        .catch((e) => e);

    authStatus === "unauthenticated" &&
      activeQRcodeGuest({
        QRID: codeInfo.QRID,
        sourcemap: `s3://${awsExports.BUCKET}/assets02/${codeInfo.QRID}${videoInfo.ext}`,
        recipient: videoInfo.recipient,
      });
  };
  useEffect(() => {
    if (progress === 100)
      setTimeout(() => {
        setProgress(0);
        codeInfo?.QRID !== "" && activateCode();
        clearQrCodeLocalDB();
        navigate("/success");
      }, 500);
  }, [progress, codeInfo]);

  const upload = async (source: string, ext: string, code: tQRCodeItem) => {
    config.DEBUG && console.log(" Click on upload button");
    if (!source) {
      config.DEBUG && console.log("Please select a file to upload.");
      return;
    }
    config.DEBUG &&
      console.log("QRCODE", code?.QRID, "------------------------");
    const filename = code?.QRID + ext;
    config.DEBUG &&
      console.log("name of video", filename, "------------------------");
    // Ensure the correct data is sent to S3
    const fileData = await fetch(source);
    const fileBlob = await fileData.blob();
    uploadTask = uploadData({
      key: "../assets02/" + filename,
      // videoInfo.orientation === "landscape"
      //   ? "../assets02/"
      //   : "../assets01/" + filename,
      data: fileBlob,
      options: {
        onProgress: ({ transferredBytes, totalBytes }) => {
          if (totalBytes) {
            const progressPercentage = Math.round(
              (transferredBytes / totalBytes) * 100
            );
            setProgress(progressPercentage); // Update the progress state
          }
        },
      },
    });
    try {
      let result = await uploadTask.result;
      config.DEBUG &&
        console.log("-----------------> info about the file ", result);
    } catch (error) {
      config.DEBUG &&
        console.log(
          "-----------------> Error is catched During Upload File",
          error
        );
      setProgress(0);
    }
  };

  return (
    <MainLayout backHeader onBackPress={() => navigate(-1)}>
      <div className={styles.middle}>
        <Text className={styles.title}>Uploading</Text>
        <UploadingSVG className={styles.image} />
        <div className={styles.value_view}>
          <Text className={styles.label}>Uploading... {progress}%</Text>
          <ProgressBar value={progress} />
        </div>
      </div>
    </MainLayout>
  );
};
export default VideoUpload;
