import { useState, useEffect } from "react";

export default function useScrollProgress() {
  const [completion, setCompletion] = useState(0);
  useEffect(() => {
    const updateScrollCompletion = () => {
      const currentProgress = window.scrollY;
      const scrollHeight = document.body.scrollHeight - window.innerHeight;
      if (scrollHeight) {
        const progress = Math.min(currentProgress / scrollHeight, 1); //// Clamp between 0 and 1
        setCompletion(progress);
      }
      // console.log("Scroll Progress:", completion);
      // console.log("Window ScrollY:", window.scrollY);
      // console.log("Document Height:", document.body.scrollHeight);
    };
    //event
    window.addEventListener("scroll", updateScrollCompletion);
    //clear event
    return () => window.removeEventListener("scroll", updateScrollCompletion);
  }, []);
  return completion; // A value between 0 and 100
}
