import { motion } from "framer-motion";
//hooks
import useScrollProgress from "../../hooks/useScrollProgress";
import { colors } from "../../resources/theme/colors";
//variants
const variants = {
  hidden: { opacity: 0 },
  enter: { opacity: 1 },
};
const VerticalScrollBar = ({ children }: any) => {
  const completion = useScrollProgress();
  const maxHeight = window.innerHeight * 0.65; // Define the max height for the background bar
  return (
    <>
      <motion.main
        variants={variants}
        initial="hidden"
        animate="enter"
        transition={{ type: "linear", delay: 0.2, duration: 0.4 }}
      >
        {children}
      </motion.main>
      {/*completion bar*/}
      <span
        style={{
          position: "fixed",
          height: maxHeight, // Base height
          zIndex: 50,
          backgroundColor: colors.secondary,
          width: 8,
          top: 200,
          right: 20,
          bottom: -20,
          borderRadius: 8,
        }}
      ></span>
      <span
        style={{
          transition: "height 0.2s ease-in-out",
          position: "fixed",
          height: `${completion * maxHeight}px`, // Dynamically set height relative to the outer span
          zIndex: 51,
          backgroundColor: colors.primary,
          width: 8,
          top: 200,
          right: 20,
          borderRadius: 8,
        }}
      ></span>
    </>
  );
};

export default VerticalScrollBar;
