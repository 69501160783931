import { useNavigate } from "react-router-dom";
import Text from "../../../components/Text";
import styles from "./styles.module.css";
import { INSTRUCTIONS, TERMS } from "../../../resources/staticData/terms";
import Button from "../../../components/Button";
import { getQueryParam } from "../../../utils/url";
import { useEffect, useState } from "react";
import { tTerm } from "../../../resources/types/items/tTerm";
import { colors } from "../../../resources/theme/colors";
import VerticalScrollBar from "../../../components/VerticalScrollBar";

import StickyHeader from "./../../../components/StickyHeader/index";
const TermsConditions = () => {
  const navigate = useNavigate();
  const [withConfirm, setWithConfirm] = useState("false");
  useEffect(() => {
    const param = getQueryParam("withConfirm");
    if (param) setWithConfirm(param);
  }, []);
  return (
    <div>
      <StickyHeader
        showBack
        onBackPress={() => navigate(-1)}
        showLogo
        title={"Terms and Conditions"}
      />
      <div className={styles.middle}>
        <VerticalScrollBar> </VerticalScrollBar>
        {TERMS.map((item: tTerm, index: number) => {
          return (
            <div key={index}>
              <Text
                className={styles.title}
                style={{
                  color: colors.primary,
                }}
              >{`${index !== 0 ? index + "." : ""} ${item.title}`}</Text>
              {typeof item.description === "string" ? (
                <Text className={`${styles.text} ${styles.largeMargin}`}>
                  {item.description}
                </Text>
              ) : (
                item.description.map((subItem, subIndex) => (
                  <div key={subIndex}>
                    <Text
                      className={`${styles.subTitle}`}
                      style={{ color: colors.variant_primary }}
                    >
                      {`${index}.${subIndex + 1}. ${subItem.subTitle}`}
                    </Text>
                    <Text className={`${styles.text} ${styles.smallMargin}`}>
                      {subItem.subDescription}
                    </Text>
                  </div>
                ))
              )}
            </div>
          );
        })}
        <Text
          className={styles.title}
          style={{
            color: colors.primary,
          }}
        >
          {INSTRUCTIONS.title}
        </Text>
        {INSTRUCTIONS.list.map((item, index) => (
          <div
            style={{
              color: "white",
              flexDirection: "row",
              display: "flex",
              gap: 10,
              alignItems: "top",
            }}
          >
            *
            <Text
              className={`${styles.text} ${styles.smallMargin}`}
              key={index}
            >
              {item}
            </Text>
          </div>
        ))}
      </div>

      <div className={styles.footer}>
        {withConfirm === "true" && (
          <Button
            className={styles.button}
            title={"Continue"}
            onPress={() => navigate(`/occasion`)}
          />
        )}
      </div>
    </div>
  );
};

export default TermsConditions;
